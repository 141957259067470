export enum UsageType {
    vehicle = 'VEHICLE',
    connectedVehicle = 'CONNECTED_VEHICLE',
}

export enum VehicleUsageType {
    standard = 'STANDARD',
    valuation = 'VALUATION',
    mot = 'MOT',
    dvla = 'DVLA',
    ulez = 'ULEZ',
    image = 'IMAGE'
}